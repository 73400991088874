"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.toRecordsDataQueryResponse = exports.toRecordDataQueryResponse = exports.RecordsDataQueryResponseDef = exports.RecordDataQueryResponseDef = void 0;
const typebox_1 = require("@sinclair/typebox");
const typebox_2 = require("../typebox");
const dataQueryPropertiesResponse_1 = require("./dataQueryPropertiesResponse");
const types_1 = require("../types");
const reportsQueryResponse_1 = require("./reportsQueryResponse");
exports.RecordDataQueryResponseDef = typebox_1.Type.Composite([
    typebox_1.Type.Object({
        id: typebox_1.Type.Optional(typebox_1.Type.String()),
        system_id: typebox_1.Type.String({ format: 'uuid' }),
        properties: typebox_1.Type.Optional(typebox_2.NestedObjectDef),
        relationships: (0, typebox_2.OptionalNullable)(dataQueryPropertiesResponse_1.DataQueryRelationshipsResponseDef),
        created_at: typebox_1.Type.String({ format: 'date-time' }),
        updated_at: typebox_1.Type.String({ format: 'date-time' }),
    }),
]);
exports.RecordsDataQueryResponseDef = typebox_1.Type.Object({
    items: typebox_1.Type.Array(exports.RecordDataQueryResponseDef),
    totalRecordsCount: typebox_1.Type.Optional(typebox_1.Type.Number()),
    trends: (0, typebox_2.OptionalNullable)(typebox_1.Type.Partial(typebox_1.Type.Record(typebox_1.Type.Enum(types_1.TrendType), typebox_1.Type.Record(typebox_1.Type.String(), reportsQueryResponse_1.ReportsQueryResponseDef)))),
}, {
    $id: 'RecordsDataQueryResponseDef',
});
const toRecordDataQueryResponse = (record) => {
    return {
        id: record.remoteId,
        system_id: record.id,
        relationships: record.relationships,
        properties: record.properties,
        created_at: record.systemCreatedAt,
        updated_at: record.systemUpdatedAt,
    };
};
exports.toRecordDataQueryResponse = toRecordDataQueryResponse;
const toRecordsDataQueryResponse = (records, totalRecordsCount) => {
    return {
        items: records.map(exports.toRecordDataQueryResponse),
        totalRecordsCount: totalRecordsCount,
    };
};
exports.toRecordsDataQueryResponse = toRecordsDataQueryResponse;
