import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useWorkspace } from '../../../features/auth/hooks/useWorkspace';
import { useAccount } from '../../../features/auth/hooks/useAccount';
import { tracking, TrackingEvent } from '../../../tracking';
import { useUser } from '../../../features/auth/hooks/useUser';
import { AppRoutes } from '../../../routes';
import { ProtectedMembersWorkspacesDetailData } from '@bigdelta/lib-api-client';
import { useQueryKeys } from '../../../features/auth/hooks/useQueryKeys';
import { useQueryClient } from '@tanstack/react-query';

export const useSwitchWorkspace = () => {
  const navigate = useNavigate();
  const queryKeys = useQueryKeys();
  const queryClient = useQueryClient();

  const { workspaces } = useUser();
  const { selectAccount, currentAccountId } = useAccount();

  const { selectWorkspace, currentWorkspaceId } = useWorkspace();
  const activeWorkspace = workspaces.find((w) => w.id === currentWorkspaceId);

  const invalidateData = useCallback(() => {
    queryClient.invalidateQueries(queryKeys.workspace());
  }, [queryClient, queryKeys]);

  const switchWorkspace = useCallback(
    (workspace: ProtectedMembersWorkspacesDetailData[number]) => {
      tracking.track(TrackingEvent.WorkspaceSwitched, {
        from_workspace_id: activeWorkspace.id,
        from_workspace_name: activeWorkspace.name,
        to_workspace_id: workspace.id,
        to_workspace_name: workspace.name,
      });
      selectWorkspace(workspace.id);
      selectAccount(currentAccountId);
      invalidateData();
      navigate(AppRoutes.ROOT);
    },
    [activeWorkspace.id, activeWorkspace.name, currentAccountId, invalidateData, navigate, selectAccount, selectWorkspace]
  );

  return switchWorkspace;
};
