"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IntegrationType = void 0;
var IntegrationType;
(function (IntegrationType) {
    IntegrationType["STRIPE"] = "stripe";
    IntegrationType["PAYME"] = "payme";
    IntegrationType["PAYPAL"] = "paypal";
    IntegrationType["SLACK"] = "slack";
    IntegrationType["BIGQUERY"] = "bigquery";
    IntegrationType["MANUAL"] = "manual";
})(IntegrationType || (exports.IntegrationType = IntegrationType = {}));
