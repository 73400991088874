import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { FC, forwardRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { tracking, TrackingEvent } from '../../../tracking';
import { twMerge } from 'tailwind-merge';
import ChevronDownIcon from '../../../assets/icons/chevron-down.svg?react';
import { DropdownMenuItem } from './DropdownMenuItem';
import CheckCircleIcon from '../../../assets/icons/check-circle.svg?react';
import PlusIcon from '../../../assets/icons/plus.svg?react';
import { WorkspaceResponse } from '@bigdelta/lib-shared';
import { useSwitchWorkspace } from '../../../shared/tables/hooks/useSwitchWorkspace';

interface ExpandableMenuItemProps extends DropdownMenu.DropdownMenuItemProps {
  expanded: boolean;
}

export const ExpandableMenuItem = forwardRef<HTMLDivElement, ExpandableMenuItemProps>(({ children, className, expanded, ...props }, ref) => (
  <DropdownMenu.Item
    className={twMerge(
      'flex select-none items-center justify-between gap-x-2 rounded px-2 py-1.5 text-xs text-m-olive-500 outline-none radix-highlighted:bg-m-gray-200',
      className
    )}
    {...props}
    ref={ref}
  >
    {children}
    <ChevronDownIcon className={twMerge('h-4 w-4 text-m-olive-300 transition-transform', expanded && 'rotate-180')} />
  </DropdownMenu.Item>
));

export interface ProfileMenuAccountSectionProps {
  account: { id: string; name: string };
  workspaces: WorkspaceResponse[];
  isInitialExpanded: boolean;
  activeWorkspace: WorkspaceResponse;
}

export const ProfileMenuAccountSection: FC<ProfileMenuAccountSectionProps> = ({ account, workspaces, isInitialExpanded, activeWorkspace }) => {
  const navigate = useNavigate();
  const [isExpanded, setIsExpanded] = useState(isInitialExpanded);
  const handleSwitchWorkspace = useSwitchWorkspace();

  const handleCreateWorkspace = () => {
    tracking.track(TrackingEvent.WorkspaceCreationInitialized);
    navigate('/workspaces/onboarding/' + account.id);
  };

  return (
    <>
      <ExpandableMenuItem
        onSelect={(event) => {
          event.preventDefault();
          setIsExpanded((v) => !v);
        }}
        expanded={isExpanded}
      >
        {account.name}
      </ExpandableMenuItem>
      {isExpanded && (
        <>
          {workspaces.map((workspace) => (
            <DropdownMenuItem key={workspace.id} className="justify-between" onSelect={() => handleSwitchWorkspace(workspace)}>
              <div>{workspace.name}</div>
              {activeWorkspace?.id === workspace.id && <CheckCircleIcon className="h-4 w-4 text-m-blue-600" />}
            </DropdownMenuItem>
          ))}
          <DropdownMenuItem className="gap-x-1" onSelect={handleCreateWorkspace}>
            <PlusIcon className="h-4 w-4" />
            Create workspace
          </DropdownMenuItem>
        </>
      )}
    </>
  );
};
