import React from 'react';
import ReactDOM from 'react-dom/client';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import App from './app.tsx';
import './main.css';
import * as Sentry from '@sentry/react';

if (import.meta.env.VITE_APP_ENVIRONMENT === 'production') {
  Sentry.init({
    dsn: 'https://6aa46509261b5b6dfafa2f766c9bd8c8@o4507226253099008.ingest.de.sentry.io/4507226258079824',
    integrations: [],
  });
}

window.addEventListener('vite:preloadError', () => {
  window.location.reload();
});

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <HelmetProvider>
      <Helmet />
      <App />
    </HelmetProvider>
  </React.StrictMode>
);
