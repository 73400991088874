import { Transition } from '@headlessui/react';
import { ToastIcon, Toaster as ToasterPrimitive, resolveValue, toast } from 'react-hot-toast';
import CloseIcon from '../assets/icons/x-close.svg?react';

export const Toaster = () => {
  return (
    <ToasterPrimitive
      position="top-right"
      containerStyle={{ zIndex: 999999 }}
      toastOptions={{
        className: 'text-md w-96',
      }}
    >
      {(t) => (
        <Transition
          appear
          show={t.visible}
          className="border-m-300 flex transform items-center rounded-lg border bg-white p-4 shadow-2xl"
          enter="transition-all duration-200"
          enterFrom="opacity-0 scale-50"
          enterTo="opacity-100 scale-100"
          leave="transition-all duration-200"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-75"
        >
          <div className="flex items-stretch">
            <div className="flex items-center">
              <ToastIcon toast={t} />
            </div>
            <div>{resolveValue(t.message, t)}</div>
            <div className="flex items-start">
              <button onClick={() => toast.dismiss(t.id)}>
                <CloseIcon className="h-5 w-5 text-m-olive-500" />
              </button>
            </div>
          </div>
        </Transition>
      )}
    </ToasterPrimitive>
  );
};
